<template>
  <div
    class="cart-summary"
    :class="[
      { 'empty' : storeCart.items && storeCart.items.length === 0 }
    ]"
  >
    <!-- cart:back+headline START -->
    <router-link
      class="back back-with-arrow"
      :to="'/cart'"
      v-html="$t('terminal.cart.buttons.back')"
      v-if="storeCart.items && storeCart.items.length > 0"
    ></router-link>
     <h1
      v-if="storeCart.items && storeCart.items.length > 0"
      v-html="$t('terminal.cart.summary.headline')"
      class="main-headline"
    ></h1>
    <!-- cart:back+headline END -->

    <!-- cart:empty START <empty-cart
      v-if="storeCart.items && storeCart.items.length === 0"
    /> cart:empty END --> <!-- v-else PRODUCTS -->

    <!-- cart:products START -->
    <products
      :products="storeCart.items"
      :cart="cart"
      :increase="increase"
      :decrease="decrease"
      :priceFormat="priceFormat"
      v-if="storeCart.items && storeCart.items.length > 0"
    />
    <!-- cart:products END -->

    <!-- cart:actions START -->
    <actions-summary
      :products="storeCart"
      :categories="categories"
      :priceFormat="priceFormat"
      :resetStoreCart="resetStoreCart"
      :total="total"
      :sumVouchers="sumVouchers"
      :addProduct="addProduct"
      :done="done"
      :cardNr="userCardNr"
      :paymentCategoryID="paymentCategoryID"
      :box="box"
      v-if="storeCart.items && storeCart.items.length > 0"
    />
    <!-- cart:actions END -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'cart-summary',
  components: {
    // EmptyCart: () => import('../../components/cart/Empty.vue'),
    Products: () => import('../../components/cart/ProductsSummary.vue'),
    ActionsSummary: () => import('../../components/cart/ActionsSummary.vue'),
  },
  data() {
    return {
      userCardNr: '1234567812345678',

      /**
       * Box
       */
      box: null,
      boxToken: localStorage.getItem('boxToken') ? localStorage.getItem('boxToken') : 'undefined',
      boxLang: localStorage.getItem('boxLang') ? localStorage.getItem('boxLang') : 'undefined',

      paymentCategoryID: null,
    };
  },
  async created() {
    // if boxToxen then fetch box
    if (this.boxToken !== 'undefined' && this.boxLang !== 'undefined') {
      this.box = await this.FETCH_BOX();

      if (this.box) {
        this.paymentCategoryID = this.$route.query.paymentCategoryID;

        // get cart
        if (this.$store.getters.storeCart) {
          await this.setCart();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      storeCart: 'storeCart',
    }),

    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },

    total() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.sumTotal;
      }
      return 0;
    },

    sumVouchers() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.sumVouchers;
      }
      return 0;
    },

    categories() {
      if (this.$store.getters.categories) {
        return this.$store.getters.categories;
      }
      return this.$store.state.categories;
    },
  },
  methods: {
    ...mapActions([
      'addItem',
      'addItemQuantity',
      'removeItem',
      'updateItem',
      'setBox',
      'clearCart',
      'setCart',
    ]),

    async FETCH_BOX() {
      await this.setBox({
        payload: {
          token: this.boxToken,
        },
      });
      return this.$store.getters.box;
    },

    addProduct(EAN) {
      if (EAN) {
        this.addItem({
          payload: {
            ean: EAN,
            quantity: '',
          },
        });
        setTimeout(() => {
          this.scrollTo('.cart');
        }, 350);
      }
    },

    addProductQuantity(EAN, q) {
      if (EAN && q) {
        this.addItem({
          payload: {
            ean: EAN,
            quantity: q,
          },
        });
        setTimeout(() => {
          this.scrollTo('.cart');
        }, 350);
      }
    },

    removeProduct(id) {
      if (id) {
        this.removeItem(id);
      }

      // if cart empty redirect to cart (scanner)
      setTimeout(() => {
        if (this.storeCart.items.length === 0) {
          this.$router.push('/cart');
        }
      }, 350);
    },

    increase(product) {
      if (product) {
        const p = product;
        p.quantity += 1;
        this.updateItem({
          id: Number(p.id),
          quantity: p.quantity,
        });
      }
    },

    decrease(product) {
      if (product) {
        if (product.quantity > 1) {
          const p = product;
          p.quantity -= 1;
          this.updateItem({
            id: Number(p.id),
            quantity: p.quantity,
          });
        } else {
          this.removeItem(product.id);

          // if cart empty redirect to cart (scanner)
          setTimeout(() => {
            if (this.storeCart.items.length === 0) {
              this.$router.push('/cart');
            }
          }, 350);
        }
      }
    },

    scrollTo() {
      const container = this.$el;
      container.scrollTop = container.scrollHeight;
    },

    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('lang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },

    done(path) {
      if (path) {
        console.log(path);
        this.$router.push(path);
      }
    },

    resetStoreCart() {
      // reset store cart
      this.clearCart();
      // redirect to home
      // this.$router.push('/');
      // redirect to home + reload app
      window.location.assign('/');
    },
  },
};
</script>
